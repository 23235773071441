import React from 'react';

import { useRouter } from 'next/router';

import Button from '~/src/common/components/Button';
import I18n from '~/src/common/services/I18n';

import { Container, ErrorTitle, ErrorCode, ErrorMessage, CabbageImage } from './layout';

interface Props {
  code: number;
}

const ServerError = ({ code }: Props) => {
  const router = useRouter();

  const statusCode = `${code}`;
  const beforeContent = statusCode[0];
  const afterContent = statusCode[statusCode.length - 1];

  const message = beforeContent === '4' ? 'server-error.404.message' : 'server-error.500.message';

  return (
    <Container>
      <ErrorTitle>{I18n.t('server-error.error')}</ErrorTitle>
      <ErrorCode $beforeContent={beforeContent} $afterContent={afterContent}>
        <CabbageImage />
      </ErrorCode>
      <ErrorMessage>{I18n.t(message)}</ErrorMessage>
      <Button onClick={router.back}>{I18n.t('server-error.back-to-home')}</Button>
    </Container>
  );
};

export default ServerError;
