import React from 'react';

import Seo from '~/src/common/components/Seo';

import ServerError from '../common/components/ServerError';
import I18n from '../common/services/I18n';

const ErrorPage = () => (
  <>
    <Seo title={I18n.t('server-error.404.message')} />
    <ServerError code={404} />
  </>
);

export default ErrorPage;
