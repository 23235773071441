import styled from 'styled-components';

import Cabbage from '~/src/common/assets/cabbage.svg';
import { media } from '~/src/styles-constants';

export const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 252px;
  width: 100%;
  margin: 100px auto;

  ${media.M`
    max-width: 100%;
  `}

  & > h2, & > div {
    font-family: ${({ theme }) => theme.fonts.primary};
    text-transform: initial;
    color: ${({ theme }) => theme.palette.common.TEXTS};
  }
`;

export const ErrorTitle = styled.h2`
  margin-bottom: 10px;

  text-transform: capitalize;
  font-size: 30px;
  font-weight: bold;
`;

export const ErrorCode = styled.div<{
  $beforeContent: string;
  $afterContent: string;
}>`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &::after,
  &::before {
    font-size: 58px;
    font-weight: bold;
    margin: 5px;
  }

  &::after {
    content: ${({ $afterContent }) => `'${$afterContent}'`};
  }

  &::before {
    content: ${({ $beforeContent }) => `'${$beforeContent}'`};
  }
`;

export const CabbageImage = styled(Cabbage)`
  width: 45px;
  height: 45px;
  margin-bottom: 5px;

  path:first-child {
    fill: ${({ theme }) => theme.palette.primary};
  }
`;

export const ErrorMessage = styled.p`
  margin-bottom: 25px;
  font-weight: normal;
  text-align: center;
`;
